import React, { useState, useEffect } from 'react';
import { Dialog } from 'sw-ui';
import PropTypes from 'prop-types';

import DecisionButtons from '../../../components/DecisionButtons';
import CircularLoaders from '../../../components/loaders';

import { DECISIONBUTTONS } from '../../../bi/constants/decisionButtons';
import { DIALOGSTITLES } from '../../../bi/constants/trips';

import styles from '../styles/trip.module.scss';

const LABELS = {
  TAKEN: {
    INFO: (version, id) => `Изменение даты и времени в версии ${version} билета #${id}`,
    CONFIRM: 'Вы подтверждаете внесение изменений в билет?',
    BUTTONS: {
      CLOSE: 'Закрыть сообщение',
      CONFIRM: 'Подтверждаю изменение',
    },
  },
  CONTINUE_BUTTON: 'OK',
};

const ChangeVersionDateDialog = ({
  tripId,
  tripService,
  data,
  closeChangeVersionDateDialog,
  reloadPage,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [closingDocuments, setClosingDocuments] = useState([]);

  useEffect(() => {
    setLoading(true);

    tripService.checkCloseDocsChangeVersion(tripId, []).then((res) => {
      setClosingDocuments(res);
      setLoading(false);
    });
  }, []);

  const handleConfirm = async () => {
    const { Message, Success } = await tripService.sendVersionTime(data);

    if (Success) {
      return reloadPage();
    }

    return setErrorMessage(Message);
  };

  const renderContent = () => {
    if (loading) return <CircularLoaders />;

    const withClosingDocumentsInfo = () => {
      const mappingDocuments = closingDocuments.map((item, idx) => <div key={ idx }>{ item }</div>);

      return (
        <div>
          { DIALOGSTITLES.CHANGE_NOT_POSSIBLE }
          { mappingDocuments }
        </div>
      );
    };

    const withoutClosingDocumentsInfo = () => {
      const { tripItemVersionId, numberVoucher } = data;

      return (
        <div>
          { LABELS.TAKEN.INFO(tripItemVersionId, numberVoucher) }
          <div className={ styles.confirm }>
            { LABELS.TAKEN.CONFIRM }
          </div>
        </div>
      );
    };

    const renderInfo = () => {
      if (errorMessage) {
        return (
          <div>
            { errorMessage }
          </div>
        );
      }

      return closingDocuments.length ? withClosingDocumentsInfo() : withoutClosingDocumentsInfo();
    };

    const renderLabelCancel = () => {
      if (errorMessage) return LABELS.CONTINUE_BUTTON;

      return closingDocuments.length ? DECISIONBUTTONS.LABELS.CANCEL : LABELS.TAKEN.BUTTONS.CLOSE;
    };

    const labelSave = closingDocuments.length ? DECISIONBUTTONS.LABELS.CHANGE_ANYWAY : LABELS.TAKEN.BUTTONS.CONFIRM;

    return (
      <div className={ styles['user-dialog'] }>
        <div className={ styles['closing-list'] }>
          { renderInfo() }
        </div>
        <div className={ `${styles.row} ${styles.right}` }>
          <DecisionButtons
            onSave={ handleConfirm }
            onCancel={ closeChangeVersionDateDialog }
            labelSave={ labelSave }
            showSave={ !errorMessage }
            labelCancel={ renderLabelCancel() }
            loadingIncluded
            closeAfterSuccess={ false }
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog onClick={ closeChangeVersionDateDialog }>
      { renderContent() }
    </Dialog>
  );
};

ChangeVersionDateDialog.propTypes = {
  tripId: PropTypes.number.isRequired,
  tripService: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  closeChangeVersionDateDialog: PropTypes.func.isRequired,
  reloadPage: PropTypes.func.isRequired,
};

export { ChangeVersionDateDialog };
