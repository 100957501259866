import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { STATUS_TRIP } from '../../../bi/constants/trips';

import { getNumberVoucher } from '../../../bi/utils/trip';

import Aggregation from '../../account/components/account/aggregation';

import { FlatButton, Button } from '../../../components/button';
import CircularLoaders from '../../../components/loaders';

import styles from '../styles/penalty.module.css';

const LABELS = {
  TICKET: ticketNumber => `Билет ${ticketNumber}`,
  CONFIRM: 'Сохранить',
  ADD: 'Добавить',
  CANCELLATION: 'Отмена',
  TRIP_STATUS: 'Текущий статус',
  ERROR: 'Ошибка, попробуйте снова',
  TICKET_NOT_EXIST: 'Данный билет не занесен в базу статусов по билетам. Убедитесь, что полет совершен.',
};

class ChangeTicketDialog extends Component {
  static propTypes = {
    item: PropTypes.object,
    tripService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    item: null,
  };

  constructor(props) {
    super();
    const { ticketStatus } = props.tripService.get();

    this.state = {
      loading: true,
      error: false,
      ticketStatus,
    };
  }

  componentDidMount() {
    const { item: { TripItemId }, tripService } = this.props;

    this.unsubscribe = tripService.subscribe(this.updateState);

    tripService.getTicketStatus(TripItemId);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = state => this.setState({
    ...state,
  });

  handleUpdateAggregationStatus = value => this.props.tripService.updateStateTicketStatus(value);

  handleConfirm = () => {
    const { onClose, item: { TripItemId } } = this.props;
    const { ticketStatus } = this.state;

    this.props.tripService.updateTicketStatus(TripItemId, ticketStatus).then(onClose);
  }

  renderLoading = () => (
    <div className={ `${styles.form} ${styles.dialog}` }>
      <CircularLoaders />
    </div>
  )

  renderErrorMessage = () => {
    const { error } = this.state;

    if (!error) return null;

    return (
      <div className={ styles['error-message'] }>{ LABELS.TICKET_NOT_EXIST }</div>
    );
  }

  renderConfirmButton = () => {
    const { error } = this.state;

    const label = error ? LABELS.ADD : LABELS.CONFIRM;

    return (
      <Button label={ label } onClick={ this.handleConfirm } />
    );
  };

  render() {
    if (this.state.loading || !this.props.item) return this.renderLoading();

    const { item: { JsonData }, onClose } = this.props;
    const { ticketStatus, loading } = this.state;

    const data = JSON.parse(JsonData);

    const { TicketsExtended } = data;
    const ticketNumber = getNumberVoucher(TicketsExtended);

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          { LABELS.TICKET(ticketNumber) }
        </div>
        <Aggregation
          loading={ loading }
          disabled={ false }
          aggregator={ ticketStatus }
          onUpdateAggregation={ this.handleUpdateAggregationStatus }
          label={ LABELS.TRIP_STATUS }
          items={ STATUS_TRIP }
          withNoSelected
        />
        { this.renderErrorMessage() }
        <div className={ `${styles.row} ${styles.action}` }>
          { this.renderConfirmButton() }
          <FlatButton label={ LABELS.CANCELLATION } onClick={ onClose } />
        </div>
      </div>
    );
  }
}

export default ChangeTicketDialog;
