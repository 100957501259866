import React from 'react';
import PropTypes from 'prop-types';

import UploadForm from '../../../../../../components/UploadForm';

import { UPLOAD_FORM, REQUIRED_EXTENSIONS } from '../../../../../../bi/constants/trips';

import style from '../../../../styles/form.module.scss';

const LABELS = {
  ERROR_MSG: 'Произошла ошибка сервера, попробуйте позже',
};

const UploadVoucher = ({ uploadFile, customFile, resetCustomFile, valid }) => {
  const { file, filed, uploaded } = customFile;

  const errorMessage = filed ? <p className={ style['filed-message'] }>{ LABELS.ERROR_MSG }</p> : null;

  const validHtml = valid && !file ? <span className='error-msg'>{ valid }</span> : null;

  return (
    <div>
      <UploadForm
        extensions={ REQUIRED_EXTENSIONS }
        onFileAdded={ uploadFile }
        onFileRemoved={ resetCustomFile }
        chooseFileLabel={ UPLOAD_FORM.CHOOSE_FILE }
        invalidExtension={ UPLOAD_FORM.INVALID_EXTENSION }
        description={ UPLOAD_FORM.DESCRIPTION }
        uploaded={ uploaded }
        file={ file }
      />
      { validHtml }
      { errorMessage }
    </div>
  );
};

UploadVoucher.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  resetCustomFile: PropTypes.func.isRequired,
  customFile: PropTypes.object.isRequired,
  valid: PropTypes.string,
};

UploadVoucher.defaultProps = {
  valid: '',
};

export { UploadVoucher };
